import domReady from '@wordpress/dom-ready';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import Swiper from 'swiper';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

domReady(() => {
  const carouselBlock = document.querySelector('.hg-parent-categories');
  const headingCarousel = document.querySelector(
    '.hg-parent-categories__headings__swiper'
  );

  if (carouselBlock) {
    const links = document.querySelectorAll(
      '.hg-parent-categories__headings a'
    );
    const slides = document.querySelectorAll(
      '.hg-parent-categories__item-wrapper'
    );
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: '.hg-parent-categories__carousel',
        pin: true,
        scrub: true,
        start: 'top 120px',
        end: '+=' + slides.length * window.innerHeight,
      },
    });

    const headingSwiper = new Swiper(headingCarousel, {
      slidesPerView: 'auto',
    });

    links.forEach((a) => {
      a.addEventListener('click', (e) => {
        e.preventDefault();
        const linkLabel = a.getAttribute('href').replace('#', '');
        const currentST = timeline.scrollTrigger.labelToScroll(linkLabel);
        gsap.to(window, {
          duration: 1,
          scrollTo: {
            y: currentST + 600,
            autoKill: false,
          },
        });
      });
    });
    ScrollTrigger.create({
      trigger: '.hg-parent-categories__carousel',
      pin: true,
    });
    slides.forEach((slide, index) => {
      const count = slides.length - index;
      const focusableElements = slide.querySelectorAll('a, button');
      let scaleValue = 100 - count * 2;
      scaleValue = scaleValue / 100;
      timeline.to(
        slide,
        {
          y: '0%',
          scale: scaleValue,
          duration: 2,
        },
        slide.getAttribute('id')
      );
      timeline.call(setActiveAnchor, null, slide.getAttribute('id'));

      if (0 < focusableElements.length) {
        focusableElements.forEach((focusableElement) => {
          focusableElement.addEventListener('focusin', () => {
            const linkLabel = slide.getAttribute('id');
            const currentST = timeline.scrollTrigger.labelToScroll(linkLabel);
            gsap.to(window, {
              duration: 1,
              scrollTo: {
                y: currentST + 600,
                autoKill: false,
              },
            });
          });
        });
      }
    });

    function setActiveAnchor() {
      const anchor = document.querySelector(
        'a[href="#' + timeline.currentLabel() + '"]'
      );
      const wrappers = document.querySelectorAll('.hg-parent-categories__item');
      const slide = document.querySelector('#' + timeline.currentLabel());
      if (anchor) {
        const anchorIndex = Array.prototype.indexOf.call(
          anchor.parentNode.children,
          anchor
        );
        links.forEach((el) => el.classList.remove('active'));
        anchor.classList.add('active');
        headingSwiper.slideTo(anchorIndex);
      }
      if (0 < wrappers.length) {
        wrappers.forEach((wrapperEl) => wrapperEl.classList.remove('active'));
      }
      slide.parentElement.classList.add('active');
    }
  }
});
